export const WebDesignProcessItems = [
  // Left side
  {
    idx: 1,
    idxContent: '01',
    title: 'Thấu hiểu khách hàng',
    content:
      'KDIGI gặp gỡ và lắng nghe ý tưởng, mong muốn của khách hàng trong thiết kế website.',
    top: '1rem',
    left: '-7.2rem',
    right: '0',
    bottom: '0',
  },

  {
    idx: 2,
    idxContent: '02',
    title: 'Tư vấn',
    content:
      'KDIGI gặp gỡ và lắng nghe ý tưởng, mong muốn của khách hàng trong thiết kế website.',
    top: '10rem',
    left: '-5.8rem',
    right: '0',
    bottom: '0',
  },
  {
    idx: 3,
    idxContent: '03',
    title: 'Thiết kế',
    content:
      'KDIGI gặp gỡ và lắng nghe ý tưởng, mong muốn của khách hàng trong thiết kế website.',
    top: '20rem',
    left: '-7.2rem',
    right: '0',
    bottom: '0',
  },
  {
    idx: 4,
    idxContent: '04',
    title: 'Lập trình',
    content:
      'KDIGI gặp gỡ và lắng nghe ý tưởng, mong muốn của khách hàng trong thiết kế website.',
    top: '30rem',
    left: '-2rem',
    right: '0',
    bottom: '0',
  },

  // Right side
  {
    idx: 5,
    idxContent: '05',
    title: 'Kiểm thử',
    content:
      'KDIGI gặp gỡ và lắng nghe ý tưởng, mong muốn của khách hàng trong thiết kế website.',
    top: '4rem',
    left: '0',
    right: '-3.5rem',
    bottom: '0',
  },
  {
    idx: 6,
    idxContent: '06',
    title: 'Bàn giao toàn diện',
    content:
      'KDIGI gặp gỡ và lắng nghe ý tưởng, mong muốn của khách hàng trong thiết kế website.',
    top: '15rem',
    left: '0',
    right: '-12.5rem',
    bottom: '0',
  },
  {
    idx: 7,
    idxContent: '07',
    title: 'Đồng hành và phát triển',
    content:
      'KDIGI gặp gỡ và lắng nghe ý tưởng, mong muốn của khách hàng trong thiết kế website.',
    top: '27rem',
    left: '0',
    right: '-12rem',
    bottom: '0',
  },
];
