export const WebDesignServiceProfessionalItems = [
  {
    idx: 1,
    name: 'Gói host WP1',
    subName: '(cloud web hosting)',
  },

  {
    idx: 2,
    name: '10.000MB SSD',
    subName: '(dung lượng hosting)',
  },
  {
    idx: 3,
    name: '05 tên miền',
    subName: '',
  },
  {
    idx: 4,
    name: 'Không giới hạn',
    subName: 'băng thông Hosting',
  },
  {
    idx: 5,
    name: 'Không giới hạn',
    subName: 'băng thông Hosting',
  },
  {
    idx: 6,
    name: 'Hỗ trợ memcache',
    subName: '',
  },
  {
    idx: 7,
    name: 'Gói MailPro1',
    subName: '(Cloud Mail Hosting)',
  },
];
