import React from 'react';
import {graphql, navigate} from 'gatsby';
import BoxWrapper from '../../components/Wrapper/BoxWrapper';
import SectionHeading from '../../components/_baseComponents/SectionHeading/SectionHeading';
import Button from '../../components/_baseComponents/Button/Button';
import {StaticImage} from 'gatsby-plugin-image';
import {HiOutlineArrowLongRight} from '@react-icons/all-files/hi2/HiOutlineArrowLongRight';
import InteractiveCircleMenu from '../../components/_baseComponents/InteractiveCircleMenu/InteractiveCircleMenu';
import {WebDesignProcessItems} from '../../staticData/WebDesignProcessItems';
import CardWrapper from '../../components/Wrapper/CardWrapper';
import IconCheck from '../../components/Icon/IconCheck';
import {WebDesignServiceBasicItems} from '../../staticData/WebDesignServiceBasicItems';
import {WebDesignServiceAdvancedItems} from '../../staticData/WebDesignServiceAdvancedItems';
import {WebDesignServiceProfessionalItems} from '../../staticData/WebDesignServiceProfessionalItems';
import {WebDesignQandA} from '../../staticData/WebDesignQandA';
import InteractiveProcessLine from '../../components/InteractiveProcessLine/InteractiveProcessLine';
import Accordion from '../../components/Accordion/Accordion';
import Seo from '../../components/Seo/Seo';

const ButtonIcon = () => <HiOutlineArrowLongRight className="h-6 w-6" />;

const WebDesignSolutionPage = () => {
  return (
    <div className="pt-[70px] lg:pt-20">
      {/* Heading section 1 */}
      <BoxWrapper
        className={`mt-6 flex-col-reverse items-center justify-center gap-8 md:mt-8 md:flex-row md:gap-6 lg:mt-12 lg:gap-24`}
      >
        <div className={`flex flex-col items-start justify-center gap-7`}>
          <SectionHeading
            content="Thiết kế Website chuyên nghiệp theo yêu cầu"
            className="items-start lg:ml-0 "
            contentClassName={`sm:text-3xl lg:text-4xl xl:text-[3rem] xl:leading-[4rem] xl:w-[70%] text-2xl leading-[2rem]`}
            lineBlockClassName="hidden"
          />
          <p className="w-full">
            KDIGI cung cấp dịch vụ thiết kế website chuyên nghiệp để doanh
            nghiệp bạn dễ dàng sở hữu một website đẹp, đúng xu hướng của thế
            giới, thêm tự tin để xây dựng các ấn phẩm kỹ thuật số sáng tạo và
            hiệu quả.
          </p>
          <Button
            type="primary"
            content="Tư vấn báo giá"
            Icon={ButtonIcon}
            className="w-fit gap-2 px-6 py-2 font-medium"
            onClick={() => navigate('/lien-he')}
          />
        </div>
        <div className={`flex`}>
          {/* Bg effect */}
          <div className="relative left-24 w-[1px] before:absolute before:-left-[15rem] before:-top-[5rem] before:-z-10 before:block  before:h-[600px] before:w-[600px] before:rounded-full before:bg-red-600/10 before:blur-[8rem] before:will-change-[filter]  before:md:-left-[15rem]  before:md:-top-[5rem] before:lg:-left-[12rem] before:lg:-top-[10rem]  before:lg:h-[850px] before:lg:w-[850px]" />
          <StaticImage
            src="../../assets/services/Businessman_1.png"
            alt="Businessman 1"
            width={800}
            className={`w-[300px] md:w-[380px] lg:w-[450px]`}
          />
        </div>
      </BoxWrapper>

      {/* Heading section 2 */}
      <BoxWrapper
        className={`mt-12 flex-col items-center justify-center gap-8 md:mt-14 md:flex-row md:gap-6 lg:mt-20 lg:gap-24`}
      >
        <div className={`flex`}>
          {/* Bg effect */}
          <div className="relative left-24 before:absolute before:-z-10 before:block before:h-[435px] before:w-[435px] before:rounded-full before:bg-blue-600/20 before:blur-[10rem] before:will-change-[filter]" />
          <StaticImage
            src="../../assets/services/Businessman_2.png"
            alt="Businessman 2"
            width={800}
            className={`w-[300px] md:w-[380px] lg:w-[450px]`}
          />
        </div>
        <div
          className={`flex flex-col items-start justify-center gap-7 sm:w-[50%] lg:w-[50%]`}
        >
          <SectionHeading
            content="Tại sao bạn nên thiết kế Website?"
            className={`items-start sm:w-full xl:ml-0 xl:w-[50%]`}
            contentClassName={`text-2xl sm:text-3xl sm:w-full xl:text-5xl xl:leading-[4rem] lg:text-4xl`}
          />
          <p className="w-full">
            Website đóng vai trò là kênh truyền thông và là công cụ kinh doanh
            hàng đầu hiện nay. Website như bộ mặt đại diện cho bạn, doanh nghiệp
            hoặc tổ chức trên nền tảng công nghệ số 4.0 IOT. Đặc biệt, trong
            giai đoạn cao điểm của dịch Covid-19, kinh tế Việt Nam bị ảnh hưởng
            nặng nề, nhiều ngành nghề bị ảnh hưởng trực tiếp như xuất nhập khẩu,
            du lịch… nhưng doanh thu từ mua sắm online qua các trang website,
            trang thương mại điện tử B2C của nhiều doanh nghiệp vẫn tăng từ
            20-30%, thậm chí tăng mạnh với các mặt hàng thiết yếu và thiết bị y
            tế. Điều này cho thấy sự thay đổi trong hành vi mua sắm của người
            dùng đang dần chuyển sang thị trường online. Với sự chuyển đổi số và
            vai trò quan trọng của website như hiện nay, không còn lý do gì để
            bạn chần chừ việc thiết kế website và quảng bá thương hiệu trên thị
            trường Internet.
          </p>
        </div>
      </BoxWrapper>

      {/* Bg blue right effect */}
      <div className="relative before:absolute before:-right-[15vw] before:-top-[36rem] before:-z-10 before:block before:h-[870px] before:w-[870px] before:rounded-full before:bg-orange-300/10 before:blur-[8rem] before:will-change-[filter] before:md:-top-[28rem] before:md:bg-orange-300/20 before:md:blur-[8rem] before:lg:-right-[0rem] before:lg:-top-[25] before:lg:blur-[8rem]" />

      {/* Benefit */}
      <BoxWrapper
        className={`mt-12 flex-col items-center justify-center gap-5 md:mt-14 lg:mt-20 lg:flex-row lg:items-start lg:justify-start`}
      >
        <SectionHeading
          content="Lợi ích"
          className={` w-full items-center lg:ml-0 lg:w-[30%] lg:items-start`}
          contentClassName={`text-2xl sm:text-[2rem]`}
          subContent="Những lợi ích khi bạn thiết kế Website"
          subContentClassName="text-[#6D6F7C]"
        />

        <div
          className={`flex w-full flex-wrap justify-center gap-5 sm:mt-4 lg:w-[70%] lg:justify-start`}
        >
          <CardWrapper
            className={`flex w-[45%] flex-col gap-5 rounded-xl bg-white/40 p-5 sm:w-[30%]`}
          >
            <h2 className={`text-4xl font-bolder text-primary`}>01</h2>
            <p className={`text-[#969AC1]`}>
              Bộ mặt đại diện cho doanh nghiệp trên nền tảng công nghệ số
            </p>
          </CardWrapper>

          <CardWrapper
            className={`flex w-[45%] flex-col gap-5 rounded-xl bg-white/40 p-5 sm:w-[30%]`}
          >
            <h2 className={`text-4xl font-bolder text-primary`}>02</h2>
            <p className={`text-[#969AC1]`}>
              Khẳng định sự nghiêm túc, chuyên nghiệp trong kinh doanh
            </p>
          </CardWrapper>

          <CardWrapper
            className={`flex w-[45%] flex-col gap-5 rounded-xl bg-white/40 p-5 sm:w-[30%]`}
          >
            <h2 className={`text-4xl font-bolder text-primary`}>03</h2>
            <p className={`text-[#969AC1]`}>
              Giảm phụ thuộc vào đội ngũ bán hàng trực tuyến
            </p>
          </CardWrapper>

          <CardWrapper
            className={`flex w-[45%] flex-col gap-5 rounded-xl bg-white/40 p-5 sm:w-[30%]`}
          >
            <h2 className={`text-4xl font-bolder text-primary`}>04</h2>
            <p className={`text-[#969AC1]`}>
              Tiết kiệm chi phí so với quảng cáo truyền thống
            </p>
          </CardWrapper>

          <CardWrapper
            className={`flex w-[45%] flex-col gap-5 rounded-xl bg-white/40 p-5 sm:w-[30%]`}
          >
            <h2 className={`text-4xl font-bolder text-primary`}>05</h2>
            <p className={`text-[#969AC1]`}>
              Thúc đẩy sự phát triển của thương hiệu
            </p>
          </CardWrapper>
        </div>
      </BoxWrapper>

      {/* Why choose us */}
      <BoxWrapper className={'mt-12 flex-col md:mt-14 lg:mt-20'}>
        <SectionHeading
          content="Tại sao bạn nên chọn KDIGI?"
          className={`w-full items-center text-2xl sm:text-[3rem]`}
        />
        <div className="mt-8 flex flex-col flex-wrap items-center justify-center gap-8 sm:flex-row xl:flex-row">
          <CardWrapper
            className={`flex h-[10rem] w-full flex-col items-center  justify-center gap-5 rounded-xl bg-white/40 p-5 sm:w-5/12 lg:w-[30%]`}
          >
            <h2 className={`text-center] text-3xl font-bold text-primary`}>
              5+ năm
            </h2>
            <p className={`text-center text-[#969AC1]`}>Kinh nghiệm</p>
          </CardWrapper>

          <CardWrapper
            className={`flex h-[10rem] w-full flex-col items-center  justify-center gap-5 rounded-xl bg-white/40 p-5 sm:w-5/12 lg:w-[30%]`}
          >
            <h2 className={`text-center] text-3xl font-bold text-primary`}>
              20+
            </h2>
            <p className={`text-center text-[#969AC1]`}>Nhân sự cao cấp</p>
          </CardWrapper>

          <CardWrapper
            className={`flex h-[10rem] w-full flex-col items-center  justify-center gap-5 rounded-xl bg-white/40 p-5 sm:w-5/12 lg:w-[30%]`}
          >
            <h2 className={`text-center] text-3xl font-bold text-primary`}>
              2000+
            </h2>
            <p className={`text-center text-[#969AC1]`}>Khách hàng</p>
          </CardWrapper>
        </div>
      </BoxWrapper>

      <BoxWrapper
        className={`mt-12 flex-col items-center gap-8 sm:flex-row xl:w-full`}
      >
        <div className="flex w-full flex-col gap-4 md:flex-row md:flex-wrap md:justify-between lg:flex-nowrap lg:justify-center lg:gap-8">
          <CardWrapper
            className="flex flex-col gap-1 rounded-lg bg-white/40 p-5 md:w-[48%] md:gap-2 lg:rounded-xl"
            isShadow={false}
          >
            <StaticImage
              src="../../assets/about/mission-vision-image-1.png"
              alt="Elite resources"
              width={100}
              height={100}
              className="h-16 w-16"
            />

            <div className="flex flex-col gap-3">
              <h3 className="text-lg font-bolder">Nguồn lực tinh nhuệ</h3>
              <p className="text-gray-500">
                KDIGI là công ty công nghệ cung cấp các giải pháp chuyển đổi số
                cho doanh nghiệp tại Việt Nam.
              </p>
            </div>
          </CardWrapper>
          <CardWrapper
            className="flex flex-col gap-2 rounded-lg bg-white/40 p-5 md:w-[48%] lg:rounded-xl"
            isShadow={false}
          >
            <StaticImage
              src="../../assets/about/mission-vision-image-2.png"
              alt="Creative thinking"
              width={100}
              height={100}
              className="h-16 w-16"
            />
            <div className="flex flex-col gap-3">
              <h3 className="text-lg font-bolder">Tư duy sáng tạo</h3>
              <p className="text-gray-500">
                KDIGI là công ty công nghệ cung cấp các giải pháp chuyển đổi số
                cho doanh nghiệp tại Việt Nam.
              </p>
            </div>
          </CardWrapper>
          <CardWrapper
            className="flex flex-col gap-2 rounded-lg bg-white/40 p-5 md:w-[48%] lg:rounded-xl"
            isShadow={false}
          >
            <StaticImage
              src="../../assets/about/mission-vision-image-3.png"
              alt="Professional service"
              width={100}
              height={100}
              className="h-16 w-16"
            />
            <div className="flex flex-col gap-3">
              <h3 className="text-lg font-bolder">Dịch vụ chuyên nghiệp</h3>
              <p className="text-gray-500">
                KDIGI là công ty công nghệ cung cấp các giải pháp chuyển đổi số
                cho doanh nghiệp tại Việt Nam.
              </p>
            </div>
          </CardWrapper>
          <CardWrapper
            className="flex flex-col gap-1 rounded-lg bg-white/40 p-5 md:w-[48%] md:gap-2 lg:rounded-xl"
            isShadow={false}
          >
            <StaticImage
              src="../../assets/about/mission-vision-image-4.png"
              alt="Effective security"
              width={100}
              height={100}
              className="h-16 w-16"
            />
            {/* Bg effect */}
            <div className="relative before:absolute before:-left-[50vw] before:-top-[15rem] before:-z-10 before:block before:h-[50vw] before:w-[50vw] before:rounded-full before:bg-orange-400/40 before:blur-[7.5rem] md:hidden" />

            <div className="flex flex-col gap-3">
              <h3 className="text-lg font-bolder">Bảo mật hiệu quả</h3>
              <p className="text-gray-500">
                KDIGI là công ty công nghệ cung cấp các giải pháp chuyển đổi số
                cho doanh nghiệp tại Việt Nam.
              </p>
            </div>
          </CardWrapper>
        </div>
      </BoxWrapper>

      {/* Bg blue right effect */}
      <div className="relative before:absolute before:-right-[15vw] before:-top-[100rem] before:-z-10 before:block before:h-[900px] before:w-[900px] before:rounded-full before:bg-cyan-400/10 before:blur-[8rem] before:will-change-[filter] before:md:-top-[28rem] before:md:blur-[8rem] before:lg:-right-[0rem] before:lg:-top-[32rem] before:lg:bg-cyan-400/10 before:lg:blur-[8rem]" />
      {/* Bg green left effect */}
      <div className="relative before:absolute before:-left-[10vw] before:-top-[36rem] before:-z-10 before:block before:h-[1000px] before:w-[1000px] before:rounded-full before:bg-lime-300/10 before:blur-[8rem] before:will-change-[filter] before:md:-top-[28rem] before:md:blur-[8rem] before:lg:-top-[40rem] before:lg:bg-lime-300/10 before:lg:blur-[8rem]" />

      {/* Process of Web Design */}
      <BoxWrapper className={'mt-[7rem]'}>
        <SectionHeading
          content="Quy trình thiết kế Website"
          className="w-full items-center text-[3rem]"
        />
      </BoxWrapper>

      {/* Interactive Circle Reason */}
      <>
        <InteractiveCircleMenu
          items={WebDesignProcessItems}
          className="hidden lg:flex"
        />
      </>
      <>
        <InteractiveProcessLine
          items={WebDesignProcessItems}
          className="lg:hidden"
        />

        {/* For Button */}
        <div className="mt-8 flex justify-center lg:hidden">
          <Button
            type="primary"
            content="Tư vấn báo giá"
            Icon={ButtonIcon}
            className="w-fit gap-2 px-6 py-2 font-medium"
            onClick={() => navigate('/lien-he')}
          />
        </div>
      </>

      {/* Package services */}
      <BoxWrapper className={'mt-[4rem]'}>
        <SectionHeading
          content="Các gói dịch vụ thiết kế website tại KDIGI"
          className="w-full items-center text-[3rem]"
        />
      </BoxWrapper>

      <BoxWrapper
        className={` flex-col items-center justify-center gap-5 overflow-auto lg:overflow-visible xl:w-full xl:flex-row`}
      >
        <CardWrapper
          className={`flex flex-col items-center px-[2rem] py-[2rem]`}
        >
          <div className="flex flex-col items-center justify-center gap-5">
            <h1 className="text-lg font-bold text-[#2E3244]">Cơ bản</h1>
            <h1 className="text-3xl font-bold text-[#2E3244]">7.990.000đ</h1>
          </div>
          <div className="mt-8 flex flex-col gap-5">
            {WebDesignServiceBasicItems.map((item) => (
              <div key={item.idx} className="flex items-center gap-5">
                <IconCheck />
                <p className="font-semibold">
                  {item.name}{' '}
                  <span className="font-normal">{item.subName}</span>
                </p>
              </div>
            ))}
          </div>
        </CardWrapper>

        <CardWrapper
          className={`flex flex-col items-center px-[2rem] py-[3rem] md:border-2 md:border-primary lg:border-2 lg:border-primary`}
          borderClassName={`border border-2 border-primary`}
        >
          <div className="flex flex-col items-center justify-center gap-5">
            <h1 className="text-lg font-bold text-[#2E3244]">Nâng cao</h1>
            <h1 className="text-3xl font-bold text-[#2E3244]">8.990.000đ</h1>
          </div>
          <div className="mt-8 flex flex-col justify-center gap-5">
            {WebDesignServiceAdvancedItems.map((item) => (
              <div key={item.idx} className="flex items-center gap-5">
                <IconCheck />
                <p className="font-semibold">
                  {item.name}{' '}
                  <span className="font-normal">{item.subName}</span>
                </p>
              </div>
            ))}

            <div className="mt-8 flex justify-center">
              <Button
                type="primary"
                content="Liên hệ ngay"
                Icon={ButtonIcon}
                className="w-fit gap-2 px-6 py-2 font-medium"
                onClick={() => navigate('/lien-he')}
              />
            </div>
          </div>
        </CardWrapper>

        <CardWrapper
          className={`flex flex-col items-center px-[2rem] py-[2rem]`}
        >
          <div className="flex flex-col items-center justify-center gap-5">
            <h1 className="text-lg font-bold text-[#2E3244]">Chuyên nghiệp</h1>
            <h1 className="text-3xl font-bold text-[#2E3244]">9.990.000đ</h1>
          </div>
          <div className="mt-8 flex flex-col gap-5">
            {WebDesignServiceProfessionalItems.map((item) => (
              <div key={item.idx} className="flex items-center gap-5">
                <IconCheck />
                <p className="font-semibold">
                  {item.name}{' '}
                  <span className="font-normal">{item.subName}</span>
                </p>
              </div>
            ))}
          </div>
        </CardWrapper>
      </BoxWrapper>

      <BoxWrapper
        className={`mt-[4rem] w-fit flex-col items-center justify-center gap-5 xl:w-[50%]`}
      >
        <p className="text-center">
          Bạn chưa tìm thấy gói phù hợp với doanh nghiệp của mình? Chúng tôi
          luôn có những giải pháp riêng dành cho doanh nghiệp của bạn
        </p>
        <div>
          <Button
            type="outline"
            content="Liên hệ để được hỗ trợ"
            Icon={ButtonIcon}
            className="flex justify-center gap-2 px-6 py-3 font-medium md:flex-none lg:flex-none"
            onClick={() => navigate('/lien-he')}
          />
        </div>
      </BoxWrapper>

      {/* Bg red left effect */}
      <div className="relative before:absolute before:-left-[10vw] before:-top-[36rem] before:-z-10 before:block before:h-[50vw] before:w-[50vw] before:rounded-full before:bg-red-600/10 before:blur-[8rem] before:will-change-[filter] before:md:-top-[28rem] before:md:blur-[14rem] before:lg:-top-[35rem] before:lg:left-[0rem] before:lg:bg-red-600/10 before:lg:blur-[8rem]" />

      {/* Q&A */}
      <BoxWrapper className={`mt-[3rem] flex-col gap-5 sm:flex-row`}>
        <SectionHeading
          content="Một số câu hỏi thường gặp"
          className={`w-full text-2xl sm:w-[30%] sm:text-2xl xl:text-3xl`}
        />
        <div className={`flex w-full flex-col gap-5 sm:w-[70%]`}>
          <Accordion items={WebDesignQandA} />
        </div>
      </BoxWrapper>

      {/* Bg green right effect */}
      <div className="relative before:absolute before:-right-[15vw] before:-top-[36rem] before:-z-10 before:block before:h-[50vw] before:w-[50vw] before:rounded-full before:bg-lime-300/10 before:blur-[8rem] before:will-change-[filter] before:md:-top-[28rem] before:md:blur-[14rem] before:lg:-bottom-[30vw] before:lg:bg-lime-300/10   before:lg:blur-[8rem]" />
    </div>
  );
};

export default WebDesignSolutionPage;

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({data: {wpPage}}) => {
  return (
    <Seo
      yoastSeo={wpPage?.seo}
      title={wpPage?.title}
      uri={wpPage?.uri}
      description={wpPage?.content && wpPage.content.substring(150)}
    />
  );
};

export const pageQuery = graphql`
  query {
    wpPage(uri: {eq: "/dich-vu/thiet-ke-website"}) {
      id
      uri
      title
      content
      seo {
        fullHead
        canonical
      }
    }
  }
`;
