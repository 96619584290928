import React from 'react';

const IconCheck = ({className = ''}) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      className={`${className}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6376 5.60116C15.6567 5.45616 15.6667 5.31116 15.6667 5.16699C15.6667 3.18449 13.8809 1.59366 11.8992 1.86283C11.3217 0.835325 10.2217 0.166992 9.00008 0.166992C7.77842 0.166992 6.67841 0.835325 6.10091 1.86283C4.11508 1.59366 2.33341 3.18449 2.33341 5.16699C2.33341 5.31116 2.34341 5.45616 2.36258 5.60116C1.33508 6.17949 0.666748 7.27949 0.666748 8.50033C0.666748 9.72116 1.33508 10.8212 2.36258 11.3995C2.34336 11.5434 2.33361 11.6885 2.33341 11.8337C2.33341 13.8162 4.11508 15.4028 6.10091 15.1378C6.67841 16.1653 7.77842 16.8337 9.00008 16.8337C10.2217 16.8337 11.3217 16.1653 11.8992 15.1378C13.8809 15.4028 15.6667 13.8162 15.6667 11.8337C15.6667 11.6895 15.6567 11.5445 15.6376 11.3995C16.6651 10.8212 17.3334 9.72116 17.3334 8.50033C17.3334 7.27949 16.6651 6.17949 15.6376 5.60116ZM8.12925 12.1803L5.07341 9.08533L6.26008 7.91533L8.14091 9.82033L11.7467 6.24199L12.9201 7.42533L8.12925 12.1803Z"
        fill="#4767EC"
      />
    </svg>
  );
};

export default IconCheck;
